
.services-top__container{
    width: 100%;
    background-image: url('../assests/1.jpg');
    background-size: cover;
    position: relative;
}

.services-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100vw;
}


.misson-title-service{
    width: 100%;
    color: #ffff;
    font-weight: 600;

}

.services-wrapper__overview{
    padding-top: 5%;
    margin-left: 6%;
    animation: header-detail-load 1.5s ease-in;
}

.services-btn{
    margin-left: 6%;
    margin-bottom: 8%;
    margin-top: 3%;
    animation: header-btn-load 2s ease-in;

}

.servies-text__overview{
    margin: 25px 0;
    max-width: 700px;
    font-size: 22px;
    font-weight: 500;
    color: #ffff;
}


/* operation topics*/

.capabilities-title{
    font-weight: 100;

}

.top-lists{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;


}

.left-lists{
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    width: 150px;
}


.industires-functions{
    padding-top: 20px;
    padding-left: 28px;
    padding-bottom: 18px;

}


.operations{
    border: 1px solid black;
    position: relative;
    border-radius: 20px;
    padding: 20px;
    height: 180px;
    width: 1020px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.operations-container{

    top: 0;
    left: 10em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    height: 100%; 

}

.operations-list{
    border: 1px solid black;
    border-radius: 10px;
    width: 170px;
    height: 50px;
    text-align: center;
    padding-top: .5%;
    padding-bottom: .5%;
}

.operations-list:hover{
    background-color: black;
    color: white;
    cursor: pointer;
}

.operations-list{
    background-color: #4263E3;
    color: white;
}

.clientIndustries-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: end;
    margin-top: 5%;
    margin-bottom: 5%;
    border-top: 1px solid #b3a9a9;
}

.organge{
    color:#FF7033;
}



/* timeline */


.target-img__container{
    width: 75%;
    height: 100%;
    margin-bottom: 10%;
  
}

.target-engagement__container {
    position: relative;
    width: 45%;
    height: 15%;
    top: -100%;
    transition: all 1s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 2px solid #FF7033;
    border-left: 2px solid #FF7033;
    border-right: 2px solid #FF7033;
    opacity: 0;
}


.target-img__container{
    position: relative;
}


.target-img__container:hover .target-engagement__container{
    transition: all 1s ease;
    top: 0;
    opacity: 1;

}

.target-img__container:hover .target-label{
    opacity: 1;
    left: 0;

}

.target-disc{

    font-size: 13px;
    font-weight: 350;
    color: black;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 3%;
    text-align: center;
}





.target-label{
    position: relative;
    opacity: 0;
    left: -100%;
    transition: all 1s ease;
    font-size: 15px;
    font-weight: 300;

}




.targetIMG-wrapper{
    height: 100%;
    width: 100%;
}

.targetIMG{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #EEEEEE;
}

.operation-labe{
    text-align: center;
}

.last-container{
    width: 100%;
}

/* tablets */

@media  screen and (max-width: 1050px) {
    .operations{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95vw;
    }
    
    .target-label{
        font-size: 15px;
    }

    .target-img__container{
        width: 100%;
    }

    .top-lists{
        display: none;
     }
 
     .operations-container{

    }

     .industires-functions{
         display: none;
     }

     .left-lists{
         width: 100%;
     }

     .operations-list{
         margin-bottom: 10px;
     }
    
}

/* phones */

@media  screen and (max-width: 800px) {

    .operations{
        width: 95vw;
        height: 100%;
        text-align: center;
    }
}


@media  screen and (max-width: 450px) {
    .operations-container{
        width: 100%;
    }
    .operations-list{
        width: 100%;
    }

    .operations{
        height: 100%;
    }

    .target-disc{

        font-size: 9px;
    }
}

@media  screen and (max-width: 700px) {


}


@media  screen and (min-width: 1000px) {

    
    .misson-title-service {
        width: 800px;
    }

    .target-disc{

        font-size: 20px;
    }

    .operations-container{
        position: absolute;
    }

    
}