.footer-div{
    width: 100%;
    background-color:#535252;
    height: auto;
}

.footer-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 500px;
    z-index: 10;
}

.footer-left__card{
    width: 100%;
    height: 100%;
    margin-left: 3%;
    max-width: 650px;
}

.footer-border{
    margin-right: 5%;
    height: auto;
}

.footer-top__container{
    position: relative;
    width: 650px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #fff;
}

.icon-container__footer{
    top: 10px;
    height: 100px;
    width: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer-social__icons{
    top: 0;
    position: relative;
    font-size: 30px;
    width: 100%;
    height: 100%;
    cursor: pointer;

}


.footer-meidum__container{
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 3px solid #fff;

}

.footer-menu{
    margin-top: 5%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 30px;
}

.footer-logo{
    position: relative;
    text-decoration: none;
    color: #FF7033;
    cursor: pointer;
    font-size: 2rem;
    transform: scale(.9);
}

.sub-logo__footer{
    color: #fff;
    font-size: 11px;
}

.footer-links{
    text-decoration: none;
    color: #fff;
}

.footer-item{
    text-decoration: none;
    font-size: 18px;
    color:#242424;
    text-decoration: none;
    transition: all 0.2s;
}

.footer-contact__item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.footer-contact__menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 650px;
    flex-wrap: wrap;
    padding-top: 20px;
}

.fas{
    font-size: 20px;
}

.fa-home{
    color: white;
}

.fa-envelope{
    color: white;
}

.fa-phone{
    color: white;
}

.fab{
    color: #fff;

}

.contact-disc{
    color: #fff;
    max-width: 200px;
    padding-left: 10px;
}

.copy-right-container-left{
    margin-top: 5%;
    width: 100%;
}

.copy-right-left{
    text-align: center;
    color: #fff;
    font-size: 12px;
}

.copy-right-container-right{
    display: none;
    margin-bottom: 3%;
    margin-top: 5%;
    width: 100%;
}

.copy-right-right{
    text-align: center;
    color: #fff;
    font-size: 12px;
}



/* tablets */

@media  screen and (max-width: 1300px) {

    .copy-right-container-left{
        opacity: 0;
        margin-top: 5%;
        width: 100%;
    }

    .copy-right-container-right{
        display: block;
        margin-top: 5%;
        width: 100%;
    }

    .footer-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 100%;
    }

    .footer-border{
        margin-left: 3%;
        width: 100%;
    }

    .footer-left__card{
        height: 100%;
        margin-left: 3%;
        max-width: 95vw;
    }

    .footer-contact__menu{
       max-width: 100%;
    }

    .footer-top__container{
       width: 100%;
    }
}



@media  screen and (max-width: 350px) {
    .footer-links{
        padding-bottom: 3%
    }

    .footer-menu{
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
    }
}

