

textarea{
    border: 2px solid  ;
}

textarea:active{
    outline: none;
}

.input-div{
    margin-bottom: 20px;
    
} 

.input_medium{
    width: 100%;
    height: 50px
}

.input_large{
    width: 100%;
}


.input_short{
    width: 100%;
    height: 50px;
}

.input-medium__contact{
    width: 100%;
    height: 50px
}

.input-footer_short{
    width: 100%;
    height: 50px;
    margin-right: 10px;
    margin-top: 10px;
}

.input-footer_medium{
    width: 100%;
    height: 50px;

}

.input-footer_large{
    width: 100%;
    height: 75px;
}   

.input{
    border-radius: 20px;
    width: 100%;
    height: 100%;
    font-size: 20px;
    padding: 10px 15px;
    font-weight: 300;
}


/* tablets */

@media  screen and (max-width: 1300px) {
    .input{
        height: 60px;
    }
    .input-footer_medium{
        margin-bottom: 20px;
    
    }
    .input-footer_medium{
        margin-bottom: 30px;
    
    }
    
    .input-footer_short{
        margin-bottom: 30px;

    }   
    
}


/* mobile */

@media  screen and (max-width: 760px) {
    .input{
        height: 60px;
    }
    .input-footer_medium{
        margin-bottom: 30px;
    
    }
    
    .input-footer_short{
        margin-bottom: 30px;

    }   
}

@media  screen and (min-width: 1300px) {
    .input_large{
        height: 150px;

    }   
}