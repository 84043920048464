.card-container{
    border-top: 1px solid rgb(192, 192, 192);
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    height: 600px;
    width: 100%;
}

.card-container-zoom{
    opacity: 1;
    transform: scale(100%);
    transition: 1s;
}


.imgonly-card-container{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    
}



.img{
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.img-wrapper-right{
    top: 15%;
    position: relative;
    height: 450px;
    width: 600px;
}



.img-wrapper-left{
    top: 15%;
    position: relative;
    left: 8px;
    height: 450px;
    border-radius: 10px;
}

.disc-container-right{
    top: 28%;
    padding-left: 6%;
    position: relative;
    max-height: 100%;
    max-width: 700px;
    margin-bottom: 10px;
}


.disc-container-left{
    top: 28%;
    padding-left: 8%;
    position: relative;
    right: 4%;
    max-height: 100%;
    max-width: 700px;
    margin-bottom: 10px;
}

.title{
    padding-bottom:8px;
}

.title--details{
    margin-bottom: 10px;
}

.learn--button{
    padding-top: 10px;
}

.img-wrapper-nb{
    border: none;
    box-shadow:none;

}

.card-bullets{
padding-bottom: 15px;
}

.bullet-points{
    padding-bottom: 12px;
     margin-left: 20px;
    list-style-type: circle;

}

@media  screen and (max-width:1050px){
    .card-container{
        border-top: 1px solid rgb(192, 192, 192);
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        height: 100%;
        width: 100%;
      
    }

    .disc-container-right{
        width: 100%;
        height: 100%;
        padding-left: 6%;
    }

    .disc-container-left{
        margin-top: 10%;
        width: 100%;
        height: 100%;
        margin-left: 6%;
    }

    .img-wrapper-left {
        margin-top: 3%;
        padding-right: 5%;
        position: relative;
        height: auto;
        width: 100%;
        padding-left: 5%;
        
    }

    .img-wrapper-right{
        padding-right: 5%;
        position: relative;
        height: auto;
        width: 100%;
        padding-left: 5%;
    }
}


@media  screen and (min-width:1100px){
    .disc-container-right{
        width: 100%;
        height: 100%;
        padding-left: 4%;
        height: 400px;

    }
}