.header-container{
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}


.header-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.header-divider{
    border-top: 1px solid #fff;
    width: 100px;
    padding-top: 10px;
    animation: divider 1s;
}

.title-header{
    animation: about-header-title 1s ease;
}

.header-desc{
    animation: about-header-desc-title 1s ease;

}

@media  screen and (max-width: 760px) {
    .header-container{
        height: 200px
    }

}


@keyframes about-header-title {
    0%{
        transform: translateX(1000%);
    }

    100%{
        transform: translateX(0);

    }
    
}

@keyframes about-header-desc-title {
    0%{
        transform: translateX(-1000%);

    }
    100%{
        transform: translateX(0);

    }
}

@keyframes divider {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(100%);
    }
}