.about-container{

}

.what-we-do-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.our-goal-container{
    margin-top: 10%;
    margin-bottom: 10%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;

}

.our-goal-para-container{
    width: 100%;
    padding-top: 20px;

}

.our-goal-para{
    font-size: 20px;
    text-align: start;
    max-width: 800px;
    font-weight: 350;
}


.our-goal-title{
    font-size: 50px;
    font-weight: 300;
}

.findmore-container{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../assests/hero.jpeg') ;
    background-size: cover;
    justify-content: center;
    height: 30%;
}

.findmore-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}

.about-team-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.founding-team-header{
    width: 100%;
    height: 100%;
    margin-top: 6%;
    margin-bottom: 6%;
    font-weight: lighter;
    font-size: 50px;
    text-align: center;
}

.cont{
    width: 100%;
    height: 100%;
}

@media  screen and (max-width: 1300px) {
    .our-goal-container{
        left: 5%;
        width: 90vw;
    }   

}

@media  screen and (max-width: 760px) {
    .findmore-container{
    }

}