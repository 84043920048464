.casestudies-page__div{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.casestudies-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;

}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(111,111,111,0.5);
    z-index: 9;
}



.case-title{
    font-size: 70px;
    font-family: 'Montserrat';
}

@media  screen and (max-width: 1350px) {
    .casestudies-div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 5%;
    }

}

@media  screen and (max-width: 760px) {
    .case-title{
        margin-left: 8%;
    }
    
}