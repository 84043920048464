.service-modal-container{
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 50px 160px rgba(0, 0, 0, 1000);
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-height: 600px;
    z-index: 999;
    bottom: 10%;
    left: 20%;
    top: 10%;
}

.modal-background-container{
    background-color: transparent;
    position: fixed;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}


.service-modal-background-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-icon-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: end;
    position: absolute;
    width: 100%;
    z-index: 9;
}

.fa-times{
    color: black;
    font-size: 32px;
    cursor: pointer;
}

.fa-times:hover{
    color: black;
    font-size: 32px;
    cursor: pointer;
    transform: scale(1.1);
}


.service-title{
    color: black;
    margin-bottom: 5%;
    font-size: 30px;
    font-weight: lighter;
    line-height: 3.6rem;
    margin-left: 15px;
}

.modal-title{
    font-size: 25px;
    color: #fff;
    font-weight: lighter;





}

.service-modal-left-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    padding-left: 10px;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    margin-right: 10px;

}



.goal-container{
    background-color: #4263E3;
    margin-left: 25px;
    margin-right: 30px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
}

.deliv-container{
    background-color: #4263E3;
    margin-left: 25px;
    margin-right: 30px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
}

.goal{
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: lighter;
    padding: 10px;
}

.modal-title{
    color: #fff;
    text-align: center;
    padding-bottom: 5px;
}

.deliverables-list{
    margin-left: 30%;
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;

}


.deliverable{
    list-style-type: circle;
    text-align: center;
    padding-top: 12px;
    font-size: 20px;
    font-weight: lighter;
}

.example{
    color: #fff;
    list-style-type: circle;
    margin-left: 30%;
    padding-bottom: 6px;
    text-align: start;
    font-size: 20px;
    font-weight: lighter;
}

.deliverable-no-bullet{
    list-style-type: none;
    font-size: 20px;
    font-weight: lighter;
}


.service-modal-right-container{
    background-color: black;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100%;
    position: relative;
    width: 250px;
    right: 0;
    overflow: hidden;
}


.study-modal-title-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: inherit;
    top: 35%;
}

.study-modal-title{
    font-size: 20px;
    font-weight: lighter;
    color: #fff;
    padding-bottom: 20px;
}

.service-inner-container{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
    
}

.fa-times-service-modal::before{
    color: white;
}


@media  screen and (max-width: 1050px) {
    .service-modal-right-container{
        display: none;
    }
    .fa-times-service-modal::before{
        color: black;
    }

    .close-icon-wrapper{
        width: 100%;
        justify-content: end;
        margin-bottom: 40px;
    }

    .fa-times-service-modal::before{
       padding-right: 0px;
       bottom: 20%;
    }
    

    .service-title{
        font-size: 25px;
        margin-bottom: 5px;
        padding-top: 15px;
    }


    .goal-container{
        height: 200px;
    }

    .deliv-container{
        height: 400px;
    }

    .example{
        font-size: 17px;
        margin-left: 10%;
    }

    .deliverable{
        font-size: 17px;
    }

    .deliverables-list{
        margin-left: 10%;
    }

    .goal{
        font-size: 17px;
    }
    

}