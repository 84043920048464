
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=MonteCarlo&family=Montserrat&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat,sans-serif;
}

.Home,
.services,
.products{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {

}
