:root{
    --primmary: #4472C4;
}

.btn{
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    height: 45px;
    align-items: center;
    justify-content: center;
    box-shadow:  -0.1em 0 .2em black;;
}

.btn--primary{
    font-size: 15px;
    background-color:#4263E3;
    color: #fff;
}

.btn--outline{
    font-size: 15px;
    background-color: #fff;
    color: #4263E3;
    padding: 8px 20px;
    border: 1px solid var(--primmary);
    transition: all 0.3s ease-out;
}

.btn--contact{
    font-size: 15px;
    background-color:#4472C4;
    color: #fff;
}

.btn--primary:hover{
    background: #123b81;
    color: #fff;
    font-size: 15px;
    transition: all 0.3s ease-out;
}


.btn--outline:hover{
    font-size: 15px;
    background: #123b81;
    color: #fff;
    transition: all 0.3s ease-out;
}

.btn-medium{
    padding: 8px 20px;
    font-size: 15px;
}

@media screen and(max-width: 500px) {
    .btn{
        height: 28px;
        font-size: 10px;
    }

    .btn--primary{
        height: 10px;
        font-size: 10px;
        background-color:#4263E3;
        color: #fff;
    }

    .btn--large{
        height: 10px;
        font-size: 10px;   
     }
}