*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
/* GLOBAL */
li{
    list-style: none;
    text-decoration: none;
}

.navbar-container{
    position: relative;
    display: flex;
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    height: 85px;
}


.navbar-logo{
    left: 6%;
    text-decoration: none;
    justify-self: start;
    font-size: 25px;
    color: #FF7033;
    position: absolute;
    cursor: pointer;
    font-size: 2rem;
    transform: scale(.9);
}

.sub-logo{
    color: #242424;
    font-size: 11px;
}

.navbar-logo:hover{
    transition: all .2s ease;
    transform: scale(1);
}

.menu-icon{
    opacity: 0;
}

.nav-menu{
    padding-right: 3%;
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.nav-item {
    text-decoration: none;
    font-size: 20px;
    color:#242424;
    text-decoration: none;
    transform: scale(.7);
    transition: all 0.2s;
  }

.nav-item:hover{
    cursor: pointer;
    border-bottom: 1px solid #4263E3;
    transform: scale(.7);
    color: #4F71BE;
    transition: all 0.2s ease;
  }

.nav-links{
    text-decoration: none;
    color: #242424;
}

.nav-links:hover {
    color: #4263E3;
}



  /*mobile*/

  @media  screen and (max-width: 1000px) {

    .navbar-container{

    }


    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        bottom: 0;
        border-radius: 8px;
        top: 80px;
        left: -100%;
        position: absolute;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background-color: #242424;
        left: 0;
        transition: all 0.5s ease-in;
        z-index: 1;
        opacity: 1;
    }
    
    .menu-icon{
        position: absolute;
        padding-right: 15px;
        right: 0;
        font-size: 40px;
        color: #242424;
        opacity: 1;
    }

    .nav-item{
        width: 100%;
    }

    .nav-links {
        text-decoration: none;
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
    }

    .nav-links:hover {
        background-color: white;
        border-radius: 0;
        color: black;
    }
    



  }