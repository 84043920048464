
.title{
    font-weight: 50;
    font-size: 30px;
    width: 100%;
}

.title--white{
    color: white;
    max-width: 75vw;
    font-weight: 300;
    margin-top: 10%;
    margin-bottom: 5%;
    font-size: 50px;
}

.title-header{
    color: #fff;
    font-size: 70px;
    padding: 0px;
    margin: 0px;
}


.title--top{
    text-align: center;
    color: black;
    width: 870px;
    padding: 15px 0;
    margin-bottom: 5%;
    font-size: 2.6rem;
    font-weight: 400;
    margin-top: 20px;
    line-height: 3.6rem;
}

.title--primary{
    font-weight: 50;
    font-size: 30px;
    color: black;
    width: 700px;
}

.title-big{
    font-weight: 400;
    font-size: 45px;
    color: #242424;
    width: 1000px;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
}

.title-sub{
    font-weight: 30;
    font-size: 10px;
    color: #242424;
    width: 500px;
}

.title--details{
    font-weight: 200;
    font-size: 20px;
    padding-bottom: 10px;
    margin-top: 25px;
    max-width: 550px;
    color: black;
}

@media  screen and (max-width: 1300px) {
    .title--top{
        width: 100%;
    }

    .title-big{
        width: 100%;
    }
    
}