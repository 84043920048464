.about-card-container{
    margin-left: 10%;
}

.about-info-container{
    max-width: 1000px;
    margin-right: 3%;

}

.about-img-wrapper{
    margin-right: 5%;
 
}

.about-card-container{
    display: flex;
    flex-direction: row;
}

.about-img{
    border-radius: 50%;
    width: 100%;
    height: auto;
}

a:link {
    text-decoration: none;
}


.about-name{
    font-weight: 400;

}

.about-role{
    padding: 10px 0;
    font-size: 20px;
    font-weight: 400;
}

.about-info-para{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    text-align: start;
    font-weight: 350;
}

.about-social-links{
    padding-right: 6px;
    margin-bottom: 20px;

}

/* tablets */

@media  screen and (max-width: 1250px) {
    .about-card-container{
        display: flex;
        flex-direction: column;
    }

    .about-img-wrapper{
        margin-right: 5%;
        width: 150px;
        height: 150px;
        margin-bottom: 10px;
    }
    
}

@media screen and (max-width: 1300px) {
    
}


