.modal-container{
    width: 100vh;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 50px 160px rgba(0, 0, 0, 1000);
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-height: 600px;
    z-index: 999;
    bottom: 10%;
    top: 10%;
    overflow: hidden;
}

.modal-img-wrapper{
    padding-left: 6%;
    width: 98%;
    height: 98%;
}

.modal-img{
    width: 98%;
    height: 100%;
}

.close-icon-container{
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 8px;
    max-height: 5px;
    z-index: 1;
}

.fa-times{
    color: black;
    font-size: 32px;
}

@media  screen and (max-width: 1200px) {

}

/*tables */
@media  screen and (max-width: 800px) {
    .modal-container{
        top: 20%;
        height: 75vh;
    }

    .modal-img-wrapper{
        padding-left: 6%;
        width: 98%;
        height: 98%;
    }

    .modal-img{
        width: 98%;
        height: 100%;
    }

    .fa-times{
        font-size: 25px;
    }
}

@media  screen and (max-width: 500px) {
    .modal-container{
        height: 40vh;
        overflow: hidden;

    }

    .modal-img-wrapper{
        padding-left: 6%;
        width: 98%;
        height: 98%;
    }

    .modal-img{
        width: 98%;
        height: 100%;
    }

    .fa-times{
        font-size: 15px;
    }
}

@media  screen and (max-width: 500px) {
    .modal-container{
        height: 40vh;
        overflow: hidden;
        
    }

    .modal-img-wrapper{
        padding-left: 6%;
        width: 98%;
        height: 98%;
    }

    .modal-img{
        width: 98%;
        height: 100%;
    }

    .fa-times{
        font-size: 15px;
    }
    .close-icon-container{
        padding-right: 3px;
    }
}