.industires-container{
    width: 75%;
    height: 300px;
}

.industries{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    max-height: 400px;
}

.industry{
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    justify-content: center;
    
}

.industry_bottom{
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 150px;
}


.img-top{
    opacity: 0;
    position: relative;
    bottom: -100%;
    z-index: -99;
    transition: all 0.5s ease;

}

.img-bottom{
    opacity: 0;
    bottom: -100%;
    z-index: -99;
    position: relative;
    transition: all 0.5s ease;
}

.img-top__bottom{
    top: -100%;
    z-index: -99;
    opacity: 0;
    bottom: -100%;
    position: relative;
    transition: all 0.5s ease;
}


.img-bottom__bottom{
    z-index: -99;
    top: -100%;
    position: relative;
    opacity: 1;
    transition: all 0.5s ease;
}


.industry_bottom:hover .downarrow-img{
    top: 0;
    opacity: 1;

}


.industry_bottom:hover .img-top__bottom{
    top: 0;
    opacity: 1;
    transition: all 0.5s ease;

}

.industry_bottom:hover .img-bottom__bottom{
    top: 0;
    opacity: 1;
    transition: all 0.2s ease;

}



.industry-pics__wrapper:hover  .img-top {
    opacity: 1;
    transition: all 0.2s ease;
    bottom: 0;
}

.industry-pics__wrapper:hover   .arrowup-img {
    opacity: 1;
    bottom: 0;
}

.industry-pics__wrapper:hover   .img-bottom {
    opacity: 1;
    transition: all 0.5s ease;
    bottom: 0;
}



.industry-pics__wrapper:hover   .industry-label {
    color: #fff;
}


.industry-pics__wrapper:hover   .industry-label__container{
    background-color: #123b81;
    transform: scale(1.1);
    transition: all 0.5s ease;

}



.industry-label{
    width: 100%;
    text-align: center;

}


.industry-label__container{
    border: 1px solid #242424;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    box-shadow: 2px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.arrowup-img{
    max-height: 40px;
    max-width: 20px;
    margin: 5px;
    opacity: 0;
    transition: all 200ms ease;
}

.downarrow-img{
    max-height: 40px;
    max-width: 20px;
    margin: 5px;
    opacity: 0;
    transition: all 200ms ease;
    transform: translateY(10);

}  

.industry-img{
    max-height: 50px;
    max-width: 80px;
    transition: all 200ms ease;
    opacity: 0;
}

.industry-pics__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}


/* tablets */

@media  screen and (max-width: 900px) {
    .industries{
        flex-direction: row;
        flex-wrap: wrap;
        max-height: 100%;
    }

    .industires-container{
        height: 100%;
    }
}
