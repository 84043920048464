html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}


.hero-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100vw;
}

.hero-video-container{
    position: relative;
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: start;
    object-fit: contain;
    background-image: url('../assests/hello.jpeg') ;
    background-size: cover;

}

.hero-btn{
    padding-top: 10px;
    display: flex;
    margin-top: 5%;
    animation: header-btn-load 2s ease-in;

}
.misson-content-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.button--services__wrapper{
    padding-right: 10px;
}



.misson-content-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}


.mission--wrapper{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: left ;
    padding-top: 20px;
    margin-top: 15px;
    margin-bottom: 4%;
    margin-left: 6%;
}


.mission-text{
    font-weight: 50;
    font-size: 22px;
    padding-bottom: 10px;
    margin-top: 5%;
    margin-right: 6%;
    max-width: 550px;
    color: #ffff;
    animation: header-detail-load 1.5s ease-in;
    opacity: 0;
    animation-fill-mode: forwards;

}


.mission-title{
    font-weight: 50;
    font-size: 50px;
    color: #ffff;
    width: 500px;
    opacity: 0;
    animation: header-title-load 1s ease-in;
    animation-fill-mode: forwards;

}

.bold{
    font-size: 65px;
    font-weight: 900;
    text-decoration-style: double;
}

.orange{
    color: #FF7033;
}


@keyframes header-title-load {
    0%{
        transform: translateY(20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes header-detail-load {
    0%{
        transform: translateY(20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}


@keyframes header-btn-load {
    0%{
        transform: translateY(20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}




@media  screen and (max-width: 1300px) {
    .hero-video-container{
        background-image: url('../assests/hello.jpeg') ;
        background-size: auto;

    }
}

/*mobile*/

@media  screen and (max-width: 760px) {
    .mission--wrapper{
        width: 100%;
    }
    .mission-title{
        width: 100%;
    }
    .mission-text{
        font-weight: 400;
    }

  
}



