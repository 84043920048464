.casestudies-container{
    height: 500px;
    width: 450px;
    cursor: pointer;
    margin-left: 2%;
    margin-bottom: 5%;
    animation: case-study-ani 1s ;
}

.casestudy-container{
    max-height: 500px;
    display: flex;
    box-shadow: 0 20px 80px rgb(0 0 0 / 45%);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    justify-content: space-between;
    filter: brightness(1) ;
    background-color: #765da0;
}

.casestudy-container:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1c1d25;
    opacity: 1;
    transition: all 450ms ease;
    z-index: 2;
    transform: translateY(100%);
}

.casestudies-container:hover .casestudy-container:before{
    transform: translateY(0);
    opacity: 0.7;
}


.project_description{
    opacity: 0;
    position: absolute;
    top: 38%;
    left: 30px;
    transform: translateY(100%);
    max-width: 400px;
    z-index: 3;
    transition: transform  450ms, opacity 300ms;
    text-align: left;
    color: white;
}

.casestudies-container:hover .project_description{
    opacity: 1;
    transform: translateY(5%);
}


.casestudy-img{
    width: 100%;
    height: 100%;
    border-radius: 0px;
}



.project_description--title{
    margin-bottom: 5%;
    text-align: center;
}

.project_description--studyNo{
    position: relative;
    text-align: center;
    padding: 10px;
    font-size: 30px;
    text-decoration: overline underline ;
}

.project_description--para{
    text-align: center;
}


.casestudy-title{
    margin-top: 10px;
    opacity: 0;
    text-align: center;
    margin-bottom: 15px;
}

/*tables */
@media  screen and (max-width: 1350px) {
    .casestudies-container{
        width: 40vw;
        height: auto;
        cursor: pointer;
        margin-left: 2%;
        margin-bottom: 10%;
    }

    .casestudy-title{
        opacity: 1;
    }

    
    .project_description{
        opacity: 0;
        position: absolute;
        top: 38%;
        left: 30px;
        transform: translateY(100%);
        max-width: 400px;
        z-index: 3;
        transition: transform  450ms, opacity 300ms;
        text-align: left;
        color: transparent;
    }

}
    

@media  screen and (max-width: 760px) {
    .casestudies-container{
        width: 30vw;
        height: auto;
    }

    .casestudy-title{
        font-size: 15px;

    }
    
}

@media  screen and (min-width: 1200px) {
    .casestudy-title{
        font-size: 15px;
    }

    
}



@keyframes case-study-ani {
    0%{
        transform: translateY(-100%);

    }

    100%{
        transform: translateY(0);

    }

    
}