.contact-title{
    margin-top: 5%;
    padding: 10px 0;
    font-size: 25px;
    font-weight: lighter;
    max-width: 700px;
}


.contat-details-container{
    margin-left: 8%;

}



@media  screen and (max-width: 760px) {
    
}