.contact-container{
    margin: 5% 0;
    height: 100%;
    width: 700px;
}

.contact-container__footer{
    height: 100%;
    width: 600px;
}


.contact-div{
    height: 100%;
    max-width: 600px;
}

.input-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input-short__contact{
    padding: 0;
    margin: 0;
    max-height: 35;
}

.submit-btn{
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    background-color: #4263E3;
}

.submit-btn:hover{
    color: white;
    background-color: black;
}

.email{
    width: 100%;
    height: 100px;
}

.email-success{
    background-color: #eefce0;;
    border: 1px solid #c2f193;;
    text-align: center;
    padding-top: 7%;
    font-weight: 200;
    animation: email-popup 5s ease-in-out;
    display: none;
}



.email-fail{
    background-color: red;
}

.contact-form{
    margin-top:1%;
}

/*tablets */
@media  screen and (max-width: 1300px) {
    .contact-container__footer{
        width: 95vw;
    }
    
}



/* phones */
@media  screen and (max-width: 760px) {
    .contact-container{
        width: 95%;
    }
    .input-row{
        flex-direction: column;
        justify-content: unset;
        width: 100%;
        display: inline;
    }
    
}


@keyframes email-popup  {
    0%{
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}